import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import { intentUrl, storageKeys } from "../libs/constant";

export default function useTicketInfo() {
  const [ticketInfo, setTicketInfo] = useState({ cost: 0, currentTicketCount: 0 });
  const [isDrinking, setIsDrinking] = useState(true);
  const [fetching, setFetching] = useState(true);

  const fetchTicketInfo = async () => {
    const fool = JSON.parse(await AsyncStorage.getItem(storageKeys.unlock));
    const url = `${intentUrl}?query=ticketInfo&fool=${fool}`;
    const res = await fetch(url, {}).then((r) => r.json());
    const { ticketCost, currentTicketCount } = res.data;
    return { cost: ticketCost / 100, currentTicketCount };
  };

  const unlock = async () => {
    await AsyncStorage.setItem(storageKeys.unlock, JSON.stringify("true"));
    const { cost, currentTicketCount } = await fetchTicketInfo();
    setTicketInfo({ ...ticketInfo, cost, currentTicketCount });
  };

  const toggleIsDrinking = () => {
    setIsDrinking(!isDrinking);
  };

  const onIsDrinking = () => {
    setIsDrinking(true);
  };

  const onIsNotDrinking = () => {
    setIsDrinking(false);
  };

  useEffect(() => {
    setFetching(true);
    fetchTicketInfo().then((info) => {
      setTicketInfo({ ...ticketInfo, cost: info.cost, currentTicketCount: info.currentTicketCount });
      setFetching(false);
    });
  }, []);

  const { cost, currentTicketCount } = ticketInfo;

  return {
    cost: isDrinking ? cost : cost * 0.5 < 20 ? 20 : cost * 0.5,
    currentTicketCount,
    unlock,
    fetching,
    onIsDrinking,
    onIsNotDrinking,
    isDrinking,
  };
}
