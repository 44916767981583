import { useEffect, useState } from "react";
import { ActivityIndicator, Pressable, TextInput, View } from "react-native";
import Button from "../components/Button";
import ButtonLoadingSpinner from "../components/ButtonLoadingSpinner";
import HeadingText from "../components/HeadingText";
import Arrow from "../components/svg/Arrow";
import ProfileIcon from "../components/svg/ProfileIcon";
import WhiteText from "../components/WhiteText";
import { useAuthorized, useMagic, useUser } from "../contexts/AuthContext";
import { colors, steps } from "../libs/constant";
import styles from "./styles";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { delay } from "../libs/utils";
import AnimatedHead from "../components/AnimatedHead";

export default function LoginScreen() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const { magic, logout, login, initialized, fetching } = useMagic();

  const navigation = useNavigation();

  const authorized = useAuthorized();
  const user = useUser();

  const onChange = (value) => {
    setPhoneNumber(value);
  };

  const onLogin = () => {
    let cleanNumber = phoneNumber.replace(/\D/g, "").trim();
    if (cleanNumber.length === 10) {
      cleanNumber = "1" + cleanNumber;
    }
    if (cleanNumber.length < 11) {
      return alert("this number does not look valid");
    }
    login("+" + cleanNumber);
  };

  const checkReturnToTickets = async () => {
    const returnToTicket = await AsyncStorage.getItem("ticket-nav");
    await delay();
    if (returnToTicket) {
      await AsyncStorage.removeItem("ticket-nav");
      await delay();
      navigation.navigate("Tickets", {
        step: steps.checkout,
      });
    }
  };

  useEffect(() => {
    if (authorized) {
      checkReturnToTickets();
    }
  }, [authorized]);

  return (
    <>
      {/* <HeadingText bg={colors.green}>profile</HeadingText> */}
      <View style={{ ...styles.container, justifyContent: "center" }}>
        <a style={{ color: "red", fontSize: "3rem", fontWeight: "bold", margin: 30 }} href="https://wheresthefuckingparty.com">
          location
        </a>
        <View style={{ alignItems: "center" }}>
          <View style={{ height: 100, width: 100 }}>
            <ProfileIcon />
          </View>
          {!initialized && <AnimatedHead />}
          {initialized && authorized && (
            <>
              <View style={{ padding: 20, gap: 10 }}>
                <View>
                  <WhiteText style={{ fontSize: 30, fontWeight: "bold" }}>Phone number</WhiteText>
                  <WhiteText style={{ fontSize: 18 }}>{user.phoneNumber}</WhiteText>
                </View>
                {/* <View>
                  <WhiteText style={{ fontSize: 30, fontWeight: "bold" }}>Wallet Address</WhiteText>
                  <WhiteText style={{ wordBreak: "break-all", fontSize: 18 }}>{user.publicAddress}</WhiteText>
                </View> */}
              </View>
              {authorized && <Button onPress={logout}>Logout</Button>}
            </>
          )}
          {!authorized && initialized && (
            <>
              <View style={{ width: "80%", marginBottom: 12 }}>
                <WhiteText
                  style={{
                    textAlign: "center",
                    margin: 10,
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  signin using sms
                </WhiteText>
                <TextInput
                  onChangeText={onChange}
                  style={styles.input}
                  dataDetectorTypes={"phoneNumber"}
                  keyboardType={"phone-pad"}
                  value={phoneNumber}
                  type="tel"
                  autoComplete="tel"
                  placeholder="##########"
                />
              </View>
              <Button onPress={onLogin}>
                {fetching ? (
                  <ButtonLoadingSpinner />
                ) : (
                  <View style={{ width: 60, height: 30 }}>
                    <Arrow />
                  </View>
                )}
              </Button>
            </>
          )}
        </View>
      </View>
    </>
  );
}
