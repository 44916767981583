import { Pressable, StyleSheet, Text, View } from "react-native";

export default function Button({ children, onPress, disabled }) {
  return (
    <View style={styles.buttonContainer}>
      <Pressable disabled={disabled} style={{ ...styles.button, backgroundColor: disabled ? "grey" : "white" }} onPress={onPress}>
        <Text style={styles.buttonLabel}>{children}</Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    width: 170,
    height: 60,
    marginHorizontal: 20,
    alignItems: "center",
    justifyContent: "center",
    padding: 3,
  },
  button: {
    borderRadius: 40,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  buttonIcon: {
    paddingRight: 8,
  },
  buttonLabel: {
    color: "#000",
    fontSize: 26,
    fontWeight: "bold",
  },
});
