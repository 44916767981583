// if (process.browser) {
// @ts-ignore
window._frameTimestamp = null;
// }
import "setimmediate";
import "react-native-gesture-handler";
import { StatusBar } from "expo-status-bar";
import { Platform, StyleSheet, Text, View } from "react-native";

import { SafeAreaProvider } from "react-native-safe-area-context";

import magic from "./libs/MagicConfiguration";
import Web3 from "web3";
import Navigation from "./navigation";
import { AuthProvider } from "./contexts/AuthContext";
export default function App() {
  const web3 = new Web3(magic.rpcProvider);

  const magicProps = {
    magic,
    web3,
  };

  return (
    <SafeAreaProvider>
      {/* <View style={styles.container}> */}
      {Platform.OS !== "web" && <magic.Relayer />}
      <AuthProvider>
        <Navigation magicProps={magicProps} />
      </AuthProvider>
      {/* </View> */}
      <StatusBar />
    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  imageContainer: {
    flex: 1,
    paddingTop: 58,
  },
  footerContainer: {
    flex: 1 / 3,
    alignItems: "center",
  },
  optionsContainer: {
    position: "absolute",
    bottom: 80,
  },
  optionsRow: {
    alignItems: "center",
    flexDirection: "row",
  },
});
{
  /* <meta
name="og:image"
content="/pwa/apple-touch-startup-image/apple-touch-startup-image-640x1136.png"
/> */
}
