import { useEffect } from "react";
import Animated, {
  cancelAnimation,
  useSharedValue,
  withTiming,
  withRepeat,
  useAnimatedStyle,
  Easing,
} from "react-native-reanimated";
import ProfileIcon from "./svg/ProfileIcon";

export default function AnimatedHead() {
  const rotation = useSharedValue(0);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotateZ: `${rotation.value}deg`,
        },
      ],
    };
  }, [rotation.value]);

  useEffect(() => {
    rotation.value = withRepeat(
      withTiming(360, {
        duration: 1000,
        easing: Easing.bounce,
      }),
      -1
    );
    return () => cancelAnimation(rotation);
  }, []);

  return (
    <Animated.View style={[{ width: 100, height: 100 }, animatedStyles]}>
      <ProfileIcon />
    </Animated.View>
  );
}
