import { Dimensions, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import TicketsScreen from "../screens/TicketsScreen";
import LoginScreen from "../screens/LoginScreen";
import WalletScreen from "../screens/WalletScreen";

import { CartProvider } from "../contexts/CartContext";
import { useInitialized } from "../contexts/AuthContext";

import WhiteText from "../components/WhiteText";

import ProfileIcon from "../components/svg/ProfileIcon";
import TicketIcon from "../components/svg/TicketIcon";
import BagIcon from "../components/svg/BagIcon";

import { colors } from "../libs/constant";
import linking from "./linkingConfiguation";

import AnimatedHead from "../components/AnimatedHead";

const SplashPage = () => {
  return (
    <View style={[{ flex: 1, gap: 10, backgroundColor: "#000", alignItems: "center", justifyContent: "center" }]}>
      <WhiteText style={{ fontWeight: "bold", fontSize: 40 }}>loading</WhiteText>
      <AnimatedHead />
      <WhiteText style={{ fontWeight: "bold", fontSize: 40 }}>foolz</WhiteText>
    </View>
  );
};

export default function Navigation({ magicProps }) {
  const initialized = useInitialized();

  return (
    <NavigationContainer linking={linking} headerShown={false}>
      {initialized && <RootNavigator options={{ headerShown: false }} magicProps={magicProps}></RootNavigator>}
      {!initialized && <SplashPage />}
    </NavigationContainer>
  );
}

const Stack = createNativeStackNavigator();

function RootNavigator({ magicProps }) {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Root" options={{ headerShown: false }}>
        {() => BottomTabNavigator(magicProps)}
      </Stack.Screen>
      {/* <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: "Oops!" }} /> */}
    </Stack.Navigator>
  );
}

const BottomTab = createBottomTabNavigator();

function BottomTabNavigator(props) {
  const deviceWidth = Dimensions.get("window").width;
  const tabBarHeight = deviceWidth > 768 ? 150 : 80;

  const IconContainer = ({ children }) => (
    <View style={{ width: deviceWidth > 768 ? tabBarHeight / 2 : 40 }}>{children}</View>
  );

  return (
    <BottomTab.Navigator
      initialRouteName="Tickets"
      screenOptions={{
        tabBarShowLabel: false,
        tabBarStyle: { backgroundColor: "#000", height: tabBarHeight, border: "none" },
        tabBarActiveTintColor: colors.green,
        tabBarInactiveTintColor: colors.red,
        // tabBarIcon: () => <BagIcon />,
        // tabBarActiveTintColor: Colors[colorScheme].tint,
      }}
    >
      <BottomTab.Screen
        name="Login"
        options={() => ({
          headerShown: false,
          tabBarShowLabel: false,
          tabBarIcon: ({ color }) => (
            <IconContainer>
              <ProfileIcon color={color} />
            </IconContainer>
          ),
        })}
        component={LoginScreen}
      >
        {/* {() => (
          
        )} */}
        {/* {() => TabOneNavigator(header, magic, web3)} */}
      </BottomTab.Screen>
      <BottomTab.Screen
        name="Wallet"
        options={{
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <IconContainer>
              <BagIcon color={color} />
            </IconContainer>
          ),

          // tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
        }}
        component={WalletScreen}
      >
        {/* {() => TabTwoNavigator(header, web3, magic)} */}
      </BottomTab.Screen>
      <BottomTab.Screen
        name="Tickets"
        initialParams={{ step: "quantity" }}
        options={{
          headerShown: false,
          tabBarShowLabel: false,

          tabBarIcon: ({ color }) => (
            <IconContainer>
              <TicketIcon color={color} />
            </IconContainer>
          ),
        }}
      >
        {({ route, navigation }) => (
          <CartProvider>
            <TicketsScreen route={route} navigation={navigation} />
          </CartProvider>
        )}

        {/* {() => TabTwoNavigator(header, web3, magic)} */}
      </BottomTab.Screen>
    </BottomTab.Navigator>
  );
}

// const profileIcon = require("../assets/profile-icon.png");

// const useAuth = (magicProps) => {
//   const [auth, setAuth] = useState({});

//   const { magic } = magicProps;

//   const checkAuth = async (magic) => {
//     const res = await magic.user.isLoggedIn();
//     // this is just initialization
//     setAuth({ ...auth, authorized: true });
//     // alert(res);
//     return res;
//   };

//   useEffect(() => {
//     if (magic) {
//       checkAuth(magic);
//     }
//   }, [magicProps]);

//   const logout = () => {
//     magic.user.logout();
//   };
//   const { authorized } = auth;
//   return { authorized, logout };
// };
