import { createContext, useContext, useReducer } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { storageKeys } from "../libs/constant";

const MAX_QUANTITY = 8;

const initialState = {
  quantity: 0,
};

const CartContext = createContext(undefined);

const actions = {
  add_quantity: "add_quantity",
  sub_quantity: "sub_quantity",
  set_quantity: "set_quantity",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.add_quantity:
      const addedQuantity = state.quantity + 1;
      // cacheItem(quantityCacheKey, addedQuantity);
      return { ...state, quantity: addedQuantity };
    case actions.sub_quantity:
      const subQuantity = state.quantity - 1;
      // cacheItem(quantityCacheKey, subQuantity);
      return { ...state, quantity: subQuantity };
    case actions.set_quantity:
      // cacheItem(quantityCacheKey, action.quantity);
      return { ...state, quantity: action.quantity };
    default:
      return state;
  }
};

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchCachedQuantity = async () => {
    const cachedQuantity = await AsyncStorage.getItem(storageKeys.quantityCacheKey);
    if (cachedQuantity) {
      dispatch({ type: actions.set_quantity, quantity: cachedQuantity });
    }
  };

  // useEffect(() => {
  //   fetchCachedQuantity();
  // }, []);

  const value = { state, dispatch };
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export { CartContext, CartProvider };

export const useCartQuantity = () => {
  const context = useContext(CartContext);

  if (context === undefined) {
    throw new Error(" Context error in CartQuantity hook");
  }

  const { dispatch, state } = context;

  return state.quantity;
};

export const useChangeQuantity = () => {
  const context = useContext(CartContext);

  if (context === undefined) {
    throw new Error("Cart Context error in ChangeQuantity hook");
  }

  const { dispatch, state } = context;

  const addQuantity = () => {
    console.log(state);
    if (state.quantity >= MAX_QUANTITY) return;
    dispatch({ type: actions.add_quantity });
  };

  const subQuantity = () => {
    dispatch({ type: actions.sub_quantity });
  };

  const setQuantity = (quantity) => {
    if (quantity >= MAX_QUANTITY) return;
    dispatch({ type: actions.set_quantity, quantity });
  };

  const resetQuantity = () => {
    dispatch({ type: actions.set_quantity, quantity: 0 });
  };

  return { addQuantity, subQuantity, resetQuantity, setQuantity };
};
