import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function Drinker(props) {
  return (
    <Svg data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.59 26.41" {...props}>
      <G fill="none" stroke="#d070ac" strokeMiterlimit={10} data-name="Layer 1">
        <Path d="M5.38 21.13c2.97-2.97 5.72-6.16 8.27-9.85a55.24 55.24 0 00-1.5-10.24c5.31 3.12 10.62 6.24 15.8 9.58-4.83.1-9.66.45-14.45 1.05" />
        <Path d="M13.58 6.35l1.56-.58c.33.28.37.76.44 1.19s.27.92.69 1c.59.12 1.24-.65 1.71-.27.46.37.03 1.4.6 1.56.11.03.23 0 .34.02.53.06.68.76.7 1.3M.74 16.18c-1.41 1.74 3.71 6.76 6.69 8.84.51.35 1.03.72 1.64.85s1.31-.01 1.67-.52c.43-.61.21-1.45-.09-2.13-1.33-3-3.91-5.33-6.84-6.81-.67-.34-2.55-1.36-3.08-.26v.03z" />
      </G>
    </Svg>
  );
}

export default Drinker;
