import { Dimensions } from "react-native";
import WhiteText from "./WhiteText";

const HeadingText = ({ children, bg }) => (
  <WhiteText
    style={{
      fontSize: Dimensions.get("window").width > 768 ? 60 : 24,
      fontWeight: "bold",
      background: bg,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 10,
      textAlign: "center",
    }}
  >
    {children}
  </WhiteText>
);

export default HeadingText;
