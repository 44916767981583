import { createContext, useContext, useEffect, useReducer, useState } from "react";
import magic from "../libs/MagicConfiguration";
import Web3 from "web3";
import { signInUrl } from "../libs/constant";

const initialState = {
  initialized: false,
  authorized: false,
  user: {
    phoneNumber: null,
    publicAddress: null,
  },
};

const AuthContext = createContext(undefined);

const getUserData = async (magic) => {
  const res = await magic.user.getMetadata();
  const { publicAddress, phoneNumber } = res;
  return { publicAddress, phoneNumber };
};

const actionTypes = {
  initialize: "initalize",
  sign_in: "sign_in",
  sign_out: "sign_out",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.initialize:
      return { ...state, initialized: true };
    case actionTypes.sign_in:
      return { ...state, authorized: true, user: action.user };
    case actionTypes.sign_out:
      return { ...state, authorized: false, user: { phoneNumber: null, publicAddress: null } };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const web3 = new Web3(magic.rpcProvider);

  const initialize = async () => {
    console.log("what");
    try {
      await updateUserData();
    } catch (e) {
      console.log("no logged in");
    }
    console.log("ok");
    dispatch({ type: actionTypes.initialize });
  };

  const updateUserData = async () => {
    const { phoneNumber, publicAddress } = await getUserData(magic);
    dispatch({ type: actionTypes.sign_in, user: { phoneNumber, publicAddress } });
  };

  useEffect(() => {
    initialize();
  }, []);

  const magicProps = {
    magic,
    web3,
  };

  const actions = { updateUserData };

  const value = { state, dispatch, actions, ...magicProps };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };

export const useMagic = () => {
  const context = useContext(AuthContext);

  const [fetching, setFetching] = useState(false);

  if (context === undefined) {
    throw new Error("Auth Context error in Magic hook");
  }

  const {
    magic,
    dispatch,
    actions: { updateUserData },
    state: { initialized },
  } = context;

  const login = async (phoneNumber) => {
    try {
      setFetching(true);
      const did = await magic.auth.loginWithSMS({ phoneNumber });
      console.log(did);
      console.log(phoneNumber);
      // log sign in
      fetch(signInUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${did}`,
        },
        body: JSON.stringify({ phoneNumber }),
      }).then(console.log);
      await updateUserData();
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  const logout = async () => {
    setFetching(true);
    await magic.user.logout();
    setFetching(false);
    dispatch({ type: actionTypes.sign_out });
  };

  return { magic, login, logout, initialized, fetching };
};

export const useUser = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("Auth Context error in User hook");
  }

  const {
    dispatch,
    state,
    actions: { updateUserData },
  } = context;

  return state.user;
};

export const useAuthorized = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("Auth Context error in Authorized hook");
  }

  const { dispatch, state } = context;
  return state.authorized;
};

export const useInitialized = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("Auth Context error in Authorized hook");
  }

  const { dispatch, state } = context;
  return state.initialized;
};
