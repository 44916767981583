export default function Plus() {
  return (
    <svg id="b" data-name="Layer 2" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.82 80.82">
      <g id="c" data-name="Layer 1">
        <rect x="0" y="0" width="80.82" height="80.82" rx="40.41" ry="40.41" fill="#fff" />
        <g>
          <line x1="40.41" y1="17.21" x2="40.41" y2="63.6" fill="#fff" stroke="#231f20" strokeMiterlimit="10" strokeWidth="8" />
          <line x1="63.6" y1="40.41" x2="17.21" y2="40.41" fill="#fff" stroke="#231f20" strokeMiterlimit="10" strokeWidth="8" />
        </g>
      </g>
    </svg>
  );
}
