import { Dimensions, Pressable, ScrollView, StyleSheet, Text, View, Button as NormalButton, ActivityIndicator } from "react-native";
import Checkbox from "expo-checkbox";

import styles from "./styles";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import CheckoutForm from "../components/CheckoutForm";
import { useCartQuantity, useChangeQuantity } from "../contexts/CartContext";
import Button from "../components/Button";
import { colors, intentUrl, steps, storageKeys } from "../libs/constant";
import { useNavigation } from "@react-navigation/native";
import Receipt from "../components/Receipt";
import { delay } from "../libs/utils";
import ButtonLoadingSpinner from "../components/ButtonLoadingSpinner";

import TicketImg from "../components/svg/TicketImg";
import Arrow from "../components/svg/Arrow";
import Plus from "../components/svg/Plus";
import Sub from "../components/svg/Sub";
import { useAuthorized, useMagic, useUser } from "../contexts/AuthContext";
import HeadingText from "../components/HeadingText";

import AsyncStorage from "@react-native-async-storage/async-storage";
import ProfileIcon from "../components/svg/ProfileIcon";
import { cacheItem, getCachedItem, removeCachedItem } from "../libs/Storage";
import useTicketInfo from "../hooks/useTicketInfo";
import AnimatedHead from "../components/AnimatedHead";
import config from "../libs/config";
import WhiteText from "../components/WhiteText";
import Deets from "../components/svg/Deets";
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withSpring } from "react-native-reanimated";
import { TextInput } from "react-native-gesture-handler";
import magic from "../libs/MagicConfiguration";

const pubKey = config.stripePubKey;
const stripePromise = loadStripe(pubKey);

export default function TicketsScreen({ route, navigation }) {
  const quantity = useCartQuantity();
  const [step, setStep] = useState(steps.quantity);

  const { cost, unlock, isDrinking, onIsDrinking, onIsNotDrinking } = useTicketInfo();

  useEffect(() => {
    if (route.params) {
      setStep(route.params.step);
    }
  }, [route]);

  return (
    <View style={styles.container}>
      <Text style={styles.topBar}>
        {quantity}{" "}
        <View style={{ flex: "0 0 20%", height: Dimensions.get("window").width > 768 ? 150 : 70 }}>
          <TicketImg rotate={45} />
        </View>
        ${quantity * cost}
      </Text>
      {step === steps.quantity && <Quantity unlock={unlock} onIsDrinking={onIsDrinking} onIsNotDrinking={onIsNotDrinking} />}
      {step === steps.checkout && <Checkout isDrinking={isDrinking} />}
      {step === steps.receipt && <Receipt />}
    </View>
  );
}

let silentCount = 0;
let unlocked = false;
const Quantity = ({ unlock, onIsDrinking, onIsNotDrinking }) => {
  const navigation = useNavigation();
  const quantity = useCartQuantity();
  const { addQuantity, subQuantity, resetQuantity } = useChangeQuantity();

  const [changing, setChanging] = useState(false);

  const buttonDims = useSharedValue(80);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      width: buttonDims.value,
      height: buttonDims.value,
    };
  });

  const goToCheckout = async () => {
    setChanging(true);
    if (quantity < 0) {
      alert("explain to me how you will buy negative tickets, and then we will make it happen");
      resetQuantity();
      setChanging(false);
      return;
    }
    // it makes sense to fetch the secret here and pass it along do this here but we will be dumb
    // we need to refactor the screen nesting anyway
    // await fetchClientSecret();
    await delay();
    navigation.navigate("Tickets", {
      step: steps.checkout,
    });
    window.history.pushState({}, "");
    setChanging(false);
  };

  const onAdd = () => {
    if (!unlocked) silentCount++;

    if (!unlocked && silentCount >= 12) {
      unlocked = true;
      buttonDims.value = withSpring(100);
      silentCount = 0;
      unlock();
      resetQuantity();
    } else {
      buttonDims.value = withSpring(silentCount * 20 + 100);
      addQuantity();
    }
  };

  const [isChecked, setChecked] = useState(false);
  const [hasCode, setHasCode] = useState(false);

  const [specialCode, setSpecialCode] = useState("");

  const [checkingCode, setCheckingCode] = useState(false);

  useEffect(() => {
    if (isChecked) {
      onIsNotDrinking();
    } else {
      onIsDrinking();
    }
  }, [isChecked]);
  const { phoneNumber } = useUser();
  const onCheckCode = async () => {
    setCheckingCode(true);
    const did = await magic.user.getIdToken();
    const { data, statusCode } = await fetch(intentUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${did}`,
      },
      body: JSON.stringify({ code: specialCode, phoneNumber, type: "special-code" }),
    }).then((r) => r.json());
    console.log(data, statusCode);
    if (statusCode === 200) {
      if (data.freebie === "received") {
        alert("whoa! pizza really is life. you just got a free ticket :)");
        setHasCode(false);
        navigation.navigate("Wallet");
      }
      if (data.freebie === "already-claimed") {
        alert("I'm glad you still think pizza is life, but you already claimed your freebie");
      }
    }
    if (statusCode === 404) {
      alert("hm this is not a special code");
    }
    setSpecialCode("");
    setCheckingCode(false);
  };

  const goToLogin = async () => {
    await AsyncStorage.setItem("ticket-nav", "true");
    navigation.navigate("Login");
  };

  return (
    <>
      {/* <HeadingText>how many tix?</HeadingText> */}
      <ScrollView contentContainerStyle={{ display: "flex", flex: 1, justifyContent: "space-evenly" }}>
        {/* {fetching ? (
          <View style={{ margin: "auto", marginVertical: 0 }}>
            <AnimatedHead />
          </View>
        ) : (
          <WhiteText style={quantityStyles.infoText}>presales left: {`${50 - currentTicketCount}`}</WhiteText>
        )} */}
        <View style={{ width: 200, margin: "auto", marginVertical: 0 }}>
          <Deets isDrinking={!isChecked} />
        </View>
        <View style={{ width: 200, margin: "auto", marginVertical: 0 }}>
          <WhiteText>Options:</WhiteText>
          <View style={quantityStyles.checkboxContainer}>
            <Checkbox style={{ margin: 8 }} value={isChecked} onValueChange={setChecked} color={isChecked ? colors.red : undefined} />
            <WhiteText style={{ fontWeight: "bold" }}>I don't drink (50% off)</WhiteText>
          </View>
          <View style={quantityStyles.checkboxContainer}>
            <Checkbox style={{ margin: 8 }} value={hasCode} onValueChange={setHasCode} color={hasCode ? colors.red : undefined} />
            <WhiteText style={{ fontWeight: "bold" }}>I have a special code</WhiteText>
          </View>
          {hasCode && phoneNumber && (
            <View style={quantityStyles.inputContainer}>
              <TextInput value={specialCode} onChangeText={setSpecialCode} placeholder="show me code" style={quantityStyles.input} />
              <View style={{ border: "1px solid white" }}>
                {!checkingCode && <NormalButton color={colors.red} style={quantityStyles.checkButton} title="check" onPress={onCheckCode} />}
                {checkingCode && <ActivityIndicator style={{ margin: 8 }} size={"small"} color={"white"} />}
              </View>
            </View>
          )}
          {hasCode && !phoneNumber && (
            <View>
              <HeadingText bg={colors.red}>oops you need to sign in first</HeadingText>
              <View style={{ margin: "auto", marginTop: 20 }}>
                <View
                  style={{
                    height: 100,
                    width: 100,
                    margin: "auto",
                    marginBottom: 30,
                  }}
                >
                  <ProfileIcon />
                </View>
                <Button onPress={goToLogin}>
                  <View style={{ width: 70, transform: "rotate(180deg)" }}>
                    <Arrow />
                  </View>
                </Button>
              </View>
            </View>
          )}
        </View>
        <Animated.View style={quantityStyles.addSubContainer}>
          <Pressable style={{ display: "flex", alignItems: "center", flex: "0 0 80px" }} onPress={subQuantity}>
            <Sub />
          </Pressable>
          <Animated.View style={animatedStyle}>
            <Pressable style={{ display: "flex", alignItems: "center" }} onPress={onAdd}>
              <Plus />
            </Pressable>
          </Animated.View>
        </Animated.View>
        <View style={{ display: "flex", alignItems: "center" }}>
          <Button onPress={goToCheckout} disabled={!quantity}>
            {!changing ? (
              <View style={{ width: 70 }}>
                <Arrow />
              </View>
            ) : (
              <ButtonLoadingSpinner />
            )}
          </Button>
        </View>
      </ScrollView>
    </>
  );
};

const quantityStyles = StyleSheet.create({
  addSubContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 40,
    marginTop: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  infoText: {
    fontSize: 24,
    textAlign: "center",
    fontWeight: "bold",
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    border: "1px solid white",
    color: "white",
    padding: 4,
  },
  inputContainer: {
    flexDirection: "row",
  },
  checkButton: {
    border: "1px solid white",
    backgroundColor: colors.red,
  },
});

const Checkout = ({ isDrinking }) => {
  const quantity = useCartQuantity();
  const { setQuantity } = useChangeQuantity();
  const navigation = useNavigation();
  const authorized = useAuthorized();
  const user = useUser();
  const { magic } = useMagic();
  const [clientSecret, setClientSecret] = useState("");
  const options = {
    clientSecret,
    appearance: { theme: "night" },
  };

  const goToQuantity = () => {
    navigation.navigate("Tickets", {
      step: steps.quantity,
    });
  };

  const goToLogin = async () => {
    await AsyncStorage.setItem("ticket-nav", "true");
    navigation.navigate("Login");
  };

  const fetchClientSecret = async () => {
    try {
      const { phoneNumber } = user;
      const did = await magic.user.getIdToken();
      const fool = JSON.parse(await AsyncStorage.getItem(storageKeys.unlock));
      const { data } = await fetch(intentUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${did}`,
        },
        body: JSON.stringify({ quantity, phoneNumber, type: "intent", fool, isDrinking }),
      }).then((r) => r.json());
      console.log("intentdata", data);
      const { client_secret, quantity: serverQuantity, id, amount } = data;
      await AsyncStorage.setItem(id, JSON.stringify({ quantity, id, amount }));
      await AsyncStorage.setItem("cached-cart", JSON.stringify({ quantity, id, amount }));
      if (quantity === serverQuantity) {
        setClientSecret(client_secret);
        cacheItem(storageKeys.clientSecret, client_secret);
      } else {
        alert("bad");
      }
    } catch (e) {
      console.log("SECRET ERROR");
      removeCachedItem(storageKeys.clientSecret);
      console.log(e);
    }
  };

  const checkSecret = async () => {
    // would be part of route params for actual react native
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

    const cachedSecret = await getCachedItem(storageKeys.clientSecret);

    // use a timestamp too?
    try {
      if (quantity) {
        fetchClientSecret();
      } else if (cachedSecret) {
        const cachedCart = JSON.parse(await getCachedItem("cached-cart"));
        setQuantity(cachedCart.quantity);
        setClientSecret(cachedSecret);
      } else if (!clientSecret) {
        goToQuantity();
      }

      if (clientSecret) {
        setClientSecret(clientSecret);
      }
    } catch (e) {
      console.log("error fetching cached secret");
      removeCachedItem(storageKeys.clientSecret);
    }
  };

  useEffect(() => {
    if (authorized) checkSecret();
    if (!authorized) setClientSecret(null);
    return () => {};
  }, [authorized, quantity]);

  useEffect(() => {
    if (clientSecret === "undefined") {
      removeCachedItem("undefined");
      removeCachedItem(storageKeys.clientSecret);
    }
  }, [clientSecret]);

  const isFetchingSecret = !clientSecret;

  return (
    <>
      <View style={{ display: "flex", flex: 1, width: "100%" }}>
        {!authorized && (
          <View>
            <HeadingText bg={colors.red}>oops you need to sign in first</HeadingText>
            <View style={{ margin: "auto", marginTop: 20 }}>
              <View
                style={{
                  height: 100,
                  width: 100,
                  margin: "auto",
                  marginBottom: 30,
                }}
              >
                <ProfileIcon />
              </View>
              <Button onPress={goToLogin}>
                <View style={{ width: 70, transform: "rotate(180deg)" }}>
                  <Arrow />
                </View>
              </Button>
            </View>
          </View>
        )}
        {isFetchingSecret && authorized && (
          <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
            <AnimatedHead />
          </View>
        )}
        {!isFetchingSecret && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm goToQuantity={goToQuantity} />
          </Elements>
        )}
        {/* <Button onPress={goToQuantity}>Go back</Button> */}
      </View>
    </>
  );
};
