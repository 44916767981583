import React, { useEffect, useState } from "react";
import { PaymentElement, LinkAuthenticationElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { ActivityIndicator, Dimensions, Pressable, ScrollView, Text, View } from "react-native";
import WhiteText from "./WhiteText";
import ButtonLoadingSpinner from "./ButtonLoadingSpinner";
import TicketImg from "./svg/TicketImg";
import CheckMark from "./svg/CheckMark";
import HeadingText from "./HeadingText";
import { colors } from "../libs/constant";
import Arrow from "./svg/Arrow";
import { useChangeQuantity } from "../contexts/CartContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { removeCachedItem } from "../libs/Storage";
import AnimatedHead from "./AnimatedHead";
import Button from "../components/Button";

export default function CheckoutForm({ goToQuantity }) {
  const stripe = useStripe();
  const elements = useElements();

  const { setQuantity } = useChangeQuantity();

  const [phoneNumber, setPhoneNumber] = useState("+14159671642");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checkingIntent, setCheckingIntent] = useState(true);

  const [receipt, setReceipt] = useState("");
  console.log("RENDERING FORM");
  useEffect(() => {
    if (!stripe) {
      // setCheckingIntent(false);
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    if (!clientSecret) {
      setCheckingIntent(false);
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(async (response) => {
      const { paymentIntent } = response;
      const { amount, status, id } = paymentIntent;
      const storedIntent = await AsyncStorage.getItem(id);
      if (storedIntent) {
        const parsedIntent = JSON.parse(storedIntent);
        setQuantity(parsedIntent.quantity);
      }
      // console.log(metadata);
      // setQuantity(amount);
      setReceipt({ amount, status });
      switch (paymentIntent.status) {
        case "succeeded":
          // we don't want them checking out with the same secret again after success
          removeCachedItem("client-secret");
          removeCachedItem("cached-quantity");
          removeCachedItem("cached-cart");
          setMessage("succeeded");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
      setCheckingIntent(false);
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const return_url =
      process.env.NODE_ENV === "development" ? "http://localhost:19006/tickets/checkout" : "https://fools.raptor.pizza/tickets/checkout";
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  const fetching = isLoading || !stripe || !elements;
  const hasCompletedPayment = message === "succeeded";

  const windowHeight = Dimensions.get("window").height;

  const headingText = hasCompletedPayment ? "😎💸" : "👋💸";
  if (checkingIntent) {
    return (
      <View style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
        <AnimatedHead />
      </View>
    );
  }
  return (
    <>
      <View
        style={{
          backgroundColor: hasCompletedPayment ? colors.green : colors.red,
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
        }}
      >
        {/* {!hasCompletedPayment && ( */}
        <Pressable
          onPress={goToQuantity}
          style={{
            position: "absolute",
            width: 100,
            height: 20,
            transform: "rotate(180deg)",
          }}
        >
          <Arrow />
        </Pressable>
        {/* )} */}
        {/* {hasCompletedPayment && (
          <View style={{ position: "absolute" }}>
            <Button color="none" title="buy more" />
          </View>
        )} */}
        <HeadingText>{headingText}</HeadingText>
      </View>
      <ScrollView
        contentContainerStyle={{
          padding: 16,
          maxWidth: 600,
          margin: "auto",
        }}
      >
        {!hasCompletedPayment && (
          <>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <View style={{ marginTop: 16, paddingBottom: 10, alignItems: "center" }}>
              <Button onPress={handleSubmit} disabled={fetching} id="submit">
                {fetching ? <ButtonLoadingSpinner /> : "Pay"}
              </Button>
              <Text>{message}</Text>
            </View>
          </>
        )}
        {hasCompletedPayment && (
          <View style={{ display: "flex", gap: 30, height: windowHeight * 0.664 }}>
            {/* <View style={{ height: 80 }}>
              {hasCompletedPayment && <CheckMark />}
            </View> */}

            <View
              style={{
                height: "100%",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <WhiteText
                style={{
                  fontSize: 31,
                  fontWeight: "bold",
                  textOrientation: "upright",
                  writingMode: "vertical-lr",
                }}
              >
                CONFIRMED
              </WhiteText>

              <View style={{ width: 130 }}>
                <TicketImg />
              </View>
              <WhiteText
                style={{
                  fontSize: 31,
                  fontWeight: "bold",
                  textOrientation: "upright",
                  writingMode: "vertical-lr",
                }}
              >
                CYA THERE
              </WhiteText>
            </View>
          </View>
        )}
      </ScrollView>
    </>
  );
}
