export const intentUrl = "https://t34qlide5dpi34vlrcvwzttgbm0qwdcx.lambda-url.us-west-1.on.aws/";
export const clickUrl = "https://7s42krby7sfszv5gbf7bzsuckm0luexk.lambda-url.us-west-1.on.aws/";
export const signInUrl = "https://lwkhkhzbauukf53dee5kgcexei0nhoja.lambda-url.us-west-1.on.aws/";

export const colors = {
  red: "#ff3131",
  green: "#6ef676",
};

export const steps = {
  quantity: "quantity",
  checkout: "checkout",
  receipt: "receipt",
};

const quantityCacheKey = "cached-quantity";

export const storageKeys = {
  unlock: "unlock",
  quantityCacheKey,
  clientSecret: "client-secret",
};
