const red = "#ef4325";
const green = "#3fff26";

export default function TicketImg({ rotate = 0, checkedIn = false }) {
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 1800" style={{ transform: `rotate(${rotate}deg)` }}>
      <rect width="600" height="1800" fill={checkedIn ? green : red} />
      <g>
        <circle cx="306.26" cy="1015.5" r="227.2" fill="#231f20" />
        <g>
          <path
            d="m207.93,1095.53l-6.55-9.78h-5.28v9.78h-6.72v-30.57h13.97c3.64,0,6.47.89,8.49,2.66,2.02,1.78,3.04,4.25,3.04,7.42v.09c0,2.47-.6,4.49-1.81,6.05s-2.79,2.7-4.74,3.43l7.47,10.92h-7.86Zm.13-20.13c0-1.46-.47-2.55-1.4-3.28-.93-.73-2.23-1.09-3.89-1.09h-6.68v8.78h6.81c1.66,0,2.93-.39,3.82-1.18.89-.79,1.33-1.83,1.33-3.14v-.09Z"
            fill="#ed1c24"
          />
          <path
            d="m252.86,1095.53l-2.79-6.86h-12.92l-2.79,6.86h-6.86l13.1-30.78h6.2l13.1,30.78h-7.03Zm-9.26-22.71l-4.06,9.91h8.12l-4.06-9.91Z"
            fill="#ed1c24"
          />
          <path
            d="m297.01,1080.29c-.64,1.34-1.52,2.46-2.64,3.36-1.12.9-2.43,1.58-3.93,2.03-1.5.45-3.11.68-4.83.68h-5.11v9.17h-6.72v-30.57h12.49c1.83,0,3.47.26,4.91.76,1.44.51,2.66,1.23,3.67,2.16,1,.93,1.78,2.05,2.31,3.34.54,1.3.81,2.73.81,4.3v.09c0,1.78-.32,3.33-.96,4.67Zm-5.85-4.63c0-1.51-.49-2.66-1.46-3.45-.98-.79-2.31-1.18-4-1.18h-5.2v9.34h5.33c1.69,0,3-.44,3.93-1.33s1.4-1.99,1.4-3.3v-.09Z"
            fill="#ed1c24"
          />
          <path d="m325.96,1071.17v24.37h-6.72v-24.37h-9.3v-6.2h25.33v6.2h-9.3Z" fill="#ed1c24" />
          <path
            d="m379.49,1086.38c-.82,1.91-1.94,3.58-3.38,5.02-1.44,1.44-3.16,2.58-5.15,3.41-1.99.83-4.17,1.25-6.53,1.25s-4.53-.42-6.51-1.25c-1.98-.83-3.69-1.95-5.13-3.36-1.44-1.41-2.56-3.07-3.36-4.98-.8-1.91-1.2-3.95-1.2-6.13v-.09c0-2.18.41-4.23,1.22-6.13.81-1.91,1.94-3.58,3.38-5.02s3.16-2.58,5.15-3.41c1.99-.83,4.17-1.24,6.53-1.24s4.53.42,6.51,1.24c1.98.83,3.69,1.95,5.13,3.36,1.44,1.41,2.56,3.07,3.36,4.98.8,1.91,1.2,3.95,1.2,6.14v.09c0,2.18-.41,4.23-1.22,6.13Zm-5.81-6.13c0-1.31-.23-2.55-.68-3.71-.45-1.16-1.09-2.18-1.92-3.06-.83-.87-1.81-1.56-2.95-2.07-1.13-.51-2.37-.76-3.71-.76s-2.61.25-3.73.74c-1.12.5-2.08,1.18-2.88,2.05-.8.87-1.43,1.88-1.88,3.03-.45,1.15-.68,2.38-.68,3.69v.09c0,1.31.22,2.55.68,3.71.45,1.16,1.09,2.18,1.92,3.06.83.87,1.8,1.57,2.93,2.07,1.12.51,2.37.76,3.73.76s2.58-.25,3.71-.74c1.13-.49,2.1-1.18,2.9-2.05.8-.87,1.43-1.88,1.88-3.04.45-1.15.68-2.38.68-3.69v-.09Z"
            fill="#ed1c24"
          />
          <path
            d="m414.43,1095.53l-6.55-9.78h-5.28v9.78h-6.72v-30.57h13.97c3.64,0,6.47.89,8.49,2.66s3.04,4.25,3.04,7.42v.09c0,2.47-.6,4.49-1.81,6.05s-2.79,2.7-4.74,3.43l7.47,10.92h-7.86Zm.13-20.13c0-1.46-.47-2.55-1.4-3.28-.93-.73-2.23-1.09-3.89-1.09h-6.68v8.78h6.81c1.66,0,2.93-.39,3.82-1.18.89-.79,1.33-1.83,1.33-3.14v-.09Z"
            fill="#ed1c24"
          />
        </g>
        <path
          d="m181.03,1151.98c0,6.29,5.1,11.39,11.39,11.39s11.39-5.1,11.39-11.39-5.1-11.39-11.39-11.39-11.39,5.1-11.39,11.39Z"
          fill="#fff"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2.79"
        />
        <path
          d="m289.06,842.77c0,6.29,5.1,11.39,11.39,11.39s11.39-5.1,11.39-11.39-5.1-11.39-11.39-11.39-11.39,5.1-11.39,11.39Z"
          fill="#fff"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2.79"
        />
        <path
          d="m468.56,942.76c0,6.29,5.1,11.39,11.39,11.39s11.39-5.1,11.39-11.39-5.1-11.39-11.39-11.39-11.39,5.1-11.39,11.39Z"
          fill="#ed1c24"
          stroke="#ed1c24"
          strokeMiterlimit="10"
          strokeWidth="2.79"
        />
        <g>
          <line x1="319.55" y1="987.19" x2="362.8" y2="972.17" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
          <polyline
            points="375.07 986.18 393.7 961.24 362.28 943.73 371.71 913.84 424.08 916.46 424.08 895.77 350.76 875.09 337.14 915.42 249.67 948.94 148 902.32"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2.79"
          />
          <polyline
            points="336.09 952.38 325.52 1012.48 291.05 1034.31 316.71 1054.77"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2.79"
          />
          <line x1="291.05" y1="1024.88" x2="320.38" y2="1049.5" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
          <line x1="299.95" y1="1018.07" x2="327.71" y2="1045.31" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
          <polyline
            points="299.95 957.83 303.62 1001.35 269.58 1027.5 291.05 1049.5"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2.79"
          />
          <line x1="354.18" y1="989.11" x2="364.75" y2="964.47" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
          <line x1="358.24" y1="991.84" x2="369.38" y2="967.47" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
          <text />
          <line x1="361.84" y1="899.18" x2="433.18" y2="910.7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
          <path
            d="m371.9,899.18c0,3.9,3.17,7.07,7.07,7.07s7.07-3.17,7.07-7.07-3.17-7.07-7.07-7.07-7.07,3.17-7.07,7.07Z"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2.79"
          />
          <line x1="348.1" y1="957.83" x2="371.9" y2="973.31" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
          <line x1="261.41" y1="987.19" x2="291.05" y2="981.98" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
          <line x1="261.41" y1="987.19" x2="148" y2="902.32" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
        </g>
        <line x1="53.6" y1="1181.96" x2="481.21" y2="1181.96" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
        <line x1="188.07" y1="806.77" x2="188.07" y2="983.66" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
        <line x1="321.86" y1="1122.56" x2="568.39" y2="1122.56" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
        <g>
          <path
            d="m337.48,1211.46c0,6.29,5.1,11.39,11.39,11.39s11.39-5.1,11.39-11.39-5.1-11.39-11.39-11.39-11.39,5.1-11.39,11.39Z"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2.79"
          />
          <polyline points="360.26 1211.46 360.26 1138.62 382.09 1154.37" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
          <line x1="360.26" y1="1149.38" x2="381.39" y2="1165.09" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
        </g>
        <g>
          <path
            d="m468.56,1076.01c0,6.29,5.1,11.39,11.39,11.39s11.39-5.1,11.39-11.39-5.1-11.39-11.39-11.39-11.39,5.1-11.39,11.39Z"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2.79"
          />
          <path
            d="m520.55,1049.82c0,6.29,5.1,11.39,11.39,11.39s11.39-5.1,11.39-11.39-5.1-11.39-11.39-11.39-11.39,5.1-11.39,11.39Z"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2.79"
          />
          <polyline
            points="491.34 1079.31 491.34 998.81 543.33 998.81 543.33 1050.98"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2.79"
          />
          <line x1="491.34" y1="1010.36" x2="543.33" y2="1010.36" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
        </g>
        <path d="m413.6,1156.22s37.61,14.21,34.82,51.47" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="2.79" />
        <path d="m92.13,1002.3s37.61,14.21,34.82,51.47" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2.79" />
        <path
          d="m248.49,858.97c0,6.29,5.1,11.39,11.39,11.39s11.39-5.1,11.39-11.39-5.1-11.39-11.39-11.39-11.39,5.1-11.39,11.39Z"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2.79"
        />
        <path
          d="m132.81,750.26L11.84,1243.2l543.68,6.37,46.48-432.43-469.19-66.88Zm172.84,492.94c-125.44,0-227.13-101.69-227.13-227.13s101.69-227.13,227.13-227.13,227.13,101.69,227.13,227.13-101.69,227.13-227.13,227.13Z"
          fill={checkedIn ? green : red}
        />
        <path
          d="m274.64,1181.96c0,6.29,5.1,11.39,11.39,11.39s11.39-5.1,11.39-11.39-5.1-11.39-11.39-11.39-11.39,5.1-11.39,11.39Z"
          fill="#ed1c24"
          stroke="#ed1c24"
          strokeMiterlimit="10"
          strokeWidth="2.79"
        />
      </g>
      <g>
        <circle cx="306.26" cy="574.08" r="139.06" fill="#ffd231" />
        <path
          d="m306.26,489.41h7.06v63.5h63.5v7.06h-7.06v7.06h-7.06v14.11h-7.06v7.06h-7.06v14.11h-7.06v14.11h-7.06v7.06h-7.06v14.11h-7.06v7.06h-7.06v7.06h-7.06v7.06h-7.06v-63.5h-63.5v-7.06h7.06v-7.06h7.06v-14.11h7.06v-7.06h7.06v-14.11h7.06v-14.11h7.06v-7.06h7.06v-14.11h7.06v-7.06h7.06v-7.06h7.06v-7.06Z"
          fill="#010101"
          fillRule="evenodd"
        />
      </g>
      <g>
        <circle cx="311.84" cy="208.78" r="139.06" fill="#45b649" />
        <path
          d="m310.33,234.67h-59.47c-.32,0-.51-.37-.31-.62l60.97-82.64c.16-.21.47-.21.63,0l60.97,82.64c.19.26,0,.62-.31.62h-59.47c8.03.76,14.31,7.54,14.31,15.78,0,8.76-7.08,15.86-15.82,15.86s-15.82-7.1-15.82-15.86c0-8.25,6.28-15.02,14.31-15.78Z"
          fill="#231f20"
          fillRule="evenodd"
        />
      </g>
      <path
        d="m81.27,1437.66c20.78-6.08,42.9-4.54,64.53-3.59,21.63.95,44.05,1.11,63.95-7.42,19.9-8.53,36.67-28.01,35.35-49.62-1.31-21.61-26.67-40.08-45.74-29.81-11.02,90.54-35.15,179.48-71.42,263.17-23.27-28.28-17.6-72.12,3.16-102.29s53.21-49.84,84.66-68.59c-17.49,23.58,2.24,63.6,31.59,64.1,4.28-10,8.65-20.45,8.09-31.31s-7.66-22.23-18.42-23.86-21.81,11.25-16.02,20.47c5.45,8.69,18.51,6.92,28.11,3.33,17.34-6.48,33.76-15.42,48.61-26.46-14.65,22.28-10.52,54.97,9.2,72.91.9-14.79,12.6-26.43,18.17-40.16,5.58-13.73-.97-35.12-15.66-33.16-14.68,1.96-14.06,26.71-.8,33.31,13.26,6.6,29.27.19,41.57-8.05,33.7-22.59,57.2-59.82,63.1-99.96-31.73,28.41-52.57,68.7-57.42,111.02-1.67,14.57,2.61,34.16,17.23,35.16,9.1.62,16.46-6.92,22.41-13.84,18.52-21.53,37.03-43.05,55.55-64.58,7.03,25.99-.29,55.39-18.68,75.04-18.4,19.65-47.25,28.9-73.65,23.59,4.22-17.75,23.78-28.11,42.02-28.76,18.23-.65,35.82,5.93,53.44,10.64s37.11,7.42,53.55-.47c16.45-7.89,26.92-30.07,16.78-45.24"
        fill="none"
        stroke="#231f20"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
    </svg>
  );
}
