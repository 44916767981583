import React, { useEffect, useState } from "react";
import { PaymentElement, LinkAuthenticationElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "./Button";
import { Text, View } from "react-native";

export default function Receipt() {
  const stripe = useStripe();
  const elements = useElements();

  const [phoneNumber, setPhoneNumber] = useState("+14159671642");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("stripe");
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    console.log(clientSecret);
    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      console.log("PAY RES", paymentIntent);
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  return (
    <>
      <View style={{ paddingBottom: 10 }}>
        <Button onPress={handleSubmit} disabled={isLoading || !stripe || !elements} id="submit">
          COOL
        </Button>
        <Text>{message}</Text>
      </View>
    </>
  );
}
