export default function BagIcon({ color }) {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43.82 66"
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path
            d="m9.73,19.19S-7.65,46.59,4.05,57.62c11.7,11.03,21.96,8.69,25.85,6.68s13.92-9.69,13.92-18.05-11.7-27.07-11.7-27.07H9.73Z"
            fill="#fff"
          />
          <path
            d="m11.4,12.37s-3.68,0-3.68,3.34,2.75,4.08,2.75,4.08h22.52s1.97-.95,1.97-3.71-1.03-3.72-2.81-3.71-20.75,0-20.75,0Z"
            fill={color}
            stroke="#231f20"
            strokeMiterlimit="10"
            strokeWidth="2.81"
          />
          <line
            x1="15.73"
            y1="25.44"
            x2="17.83"
            y2="19.8"
            fill="none"
            stroke="#231f20"
            strokeMiterlimit="10"
            strokeWidth="2.35"
          />
          <line
            x1="27.27"
            y1="25.44"
            x2="25.19"
            y2="19.19"
            fill="none"
            stroke="#231f20"
            strokeMiterlimit="10"
            strokeWidth="2.35"
          />
          <polyline
            points="11.79 11.43 7.72 0 35.43 0 31.21 11.44"
            fill="#fff"
          />
          <line
            x1="17.04"
            y1="5.72"
            x2="19.54"
            y2="12.65"
            fill="none"
            stroke="#231f20"
            strokeMiterlimit="10"
            strokeWidth="2.35"
          />
          <line
            x1="26"
            y1="5.72"
            x2="23.33"
            y2="12.65"
            fill="none"
            stroke="#231f20"
            strokeMiterlimit="10"
            strokeWidth="2.35"
          />
        </g>
      </g>
    </svg>
  );
}
