import { useMemo, useRef, useState } from "react";
import { StyleSheet, SafeAreaView, FlatList, Pressable } from "react-native";

import { View } from "react-native";
import TicketImg from "../components/svg/TicketImg";
import WhiteText from "../components/WhiteText";
import { intentUrl } from "../libs/constant";
import magic from "../libs/MagicConfiguration";
import Button from "./Button";
import WalletTicket from "./WalletTicket";

export default function Bag({ tickets, totalTickets, fetchTickets }) {
  const [checkingIn, setCheckingIn] = useState(false);
  const numCheckedIn = useMemo(
    () =>
      tickets.reduce((pv, cv) => {
        if (cv.checkedIn) {
          pv = pv + 1;
        }
        return pv;
      }, 0),
    [tickets]
  );
  return (
    <SafeAreaView style={ticketStyles.ticketsContainer}>
      <WhiteText
        style={{
          textAlign: "center",
          fontSize: 28,
          fontWeight: "bold",
          margin: 10,
        }}
      >
        checkedin {numCheckedIn}/{totalTickets} tickets
      </WhiteText>
      <FlatList
        nestedScrollEnabled={true}
        data={tickets}
        keyExtractor={(ticket) => ticket.id}
        // contentContainerStyle={ticketStyles.container}
        numColumns={2}
        renderItem={({ item }) => {
          return <WalletTicket item={item} fetchTickets={fetchTickets} readyForCheckin={checkingIn} />;
        }}
      />
      <View style={ticketStyles.readyForCheckin}>
        <Button
          onPress={() => {
            setCheckingIn(!checkingIn);
          }}
        >
          {!checkingIn ? "@ the door" : "checking in!"}
        </Button>
      </View>
    </SafeAreaView>
  );
}

const ticketStyles = StyleSheet.create({
  ticketsContainer: {
    // flexDirection: "row",
    // flexWrap: "wrap",
    // justifyContent: "center",
    width: "100%",
    flex: 1,
    marginTop: 10,
    // gap: 20,
  },
  readyForCheckin: {
    position: "fixed",
    bottom: 20,
    textAlign: "center",
    alignItems: "center",
    width: "100%",
  },
});
