import { Dimensions, StyleSheet } from "react-native";
import { colors } from "../libs/constant";

export default StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#000",
    color: "#fff",
    // paddingLeft: 20,
    // paddingRight: 20,
    // paddingTop: 10,
  },
  topBar: {
    display: "flex",
    fontWeight: "bold",
    color: "#000",
    backgroundColor: "#fff",
    width: "100%",
    textAlign: "center",
    padding: 10,
    fontSize: Dimensions.get("window").width > 768 ? 60 : 30,
    justifyContent: "center",
    gap: 50,
    alignItems: "center",
  },
  plusButton: {
    fontSize: 80,
    fontWeight: "bold",
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: "50%",
    width: 80,
    height: 80,
    textAlign: "center",
    lineHeight: 64,
  },
  quantityText: {
    textAlign: "center",
    fontSize: 100,
    fontWeight: "bold",
    color: colors.red,
  },
  input: {
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "white",
    width: "100%",
    fontSize: 30,
    padding: 10,
    color: "white",
    textAlign: "center",
  },
});
