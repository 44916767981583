import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StyleSheet } from "react-native";

import { View, ScrollView } from "react-native";
import AnimatedHead from "../components/AnimatedHead";
import Bag from "../components/Bag";
import Button from "../components/Button";
import ButtonPresser from "../components/ButtonPresser";
import HeadingText from "../components/HeadingText";
import Arrow from "../components/svg/Arrow";
import BagIcon from "../components/svg/BagIcon";
import WhiteText from "../components/WhiteText";
import { useAuthorized, useMagic } from "../contexts/AuthContext";
import { clickUrl, intentUrl } from "../libs/constant";

export default function WalletScreen({ route }) {
  const navigation = useNavigation();
  const authorized = useAuthorized();
  const { magic } = useMagic();
  const [tickets, setTickets] = useState([]);
  const [fetching, setFetching] = useState(false);

  const fetchTickets = async () => {
    setFetching(true);
    const did = await magic.user.getIdToken();
    const res = await fetch(intentUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${did}`,
      },
    })
      .then((r) => r.json())
      .catch(console.log);
    const tickets = Object.values(res.data.items).reduce((pv, cv) => {
      return [
        ...pv,
        {
          id: cv.ticket_id.S,
          quantity: parseInt(cv.quantity.N || cv.quantity.S),
          checkedIn: cv.checkedIn ? cv.checkedIn.BOOL : false,
          ticketType: cv.ticketType ? cv.ticketType.S : "drinker",
        },
      ];
    }, []);

    setTickets(tickets);
    setFetching(false);
  };

  useFocusEffect(
    useCallback(() => {
      if (authorized) fetchTickets();
      if (!authorized) setTickets([]);
    }, [authorized])
  );

  const totalTickets = useMemo(() => {
    return tickets.reduce((pv, cv) => {
      return (pv += cv.quantity);
    }, 0);
  }, [tickets]);

  return (
    <ScrollView contentContainerStyle={{ background: "#000", minHeight: "100%" }}>
      {/* <HeadingText bg={colors.green}>bag</HeadingText> */}
      {fetching && (
        <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
          <AnimatedHead />
        </View>
      )}

      {tickets.length ? <Bag tickets={tickets} totalTickets={totalTickets} fetchTickets={fetchTickets} /> : <></>}

      {/* {!fetching && tickets.length === 0 && <ButtonPresser />} */}

      {/* {authorized && !fetching && tickets.length === 0 && (
        <View style={{ justifyContent: "center", alignItems: "center", padding: 20 }}>
          <HeadingText>go buy a ticket!</HeadingText>
          <Button
            onPress={() => {
              navigation.navigate("Tickets");
            }}
          >
            <View style={{ width: 70, transform: "rotate(0deg)" }}>
              <Arrow />
            </View>
          </Button>
        </View>
      )} */}
      {authorized && !fetching && tickets.length === 0 && (
        <View style={{ justifyContent: "center", alignItems: "center", marginVertical: 20 }}>
          <HeadingText style={{ textAlign: "center", margin: 4 }}>your tickets will appear here</HeadingText>

          <View style={{ height: 100, width: 100 }}>
            <BagIcon color={"#FFF"} />
          </View>
          <HeadingText style={{ textAlign: "center", margin: 4 }}>buy tix</HeadingText>
          <View style={{}}>
            <Button
              onPress={() => {
                navigation.navigate("Tickets");
              }}
            >
              <View style={{ width: 70, transform: ")" }}>
                <Arrow />
              </View>
            </Button>
          </View>
        </View>
      )}
      {!authorized && !fetching && (
        <View style={{ justifyContent: "center", alignItems: "center", marginVertical: 20 }}>
          <HeadingText style={{ textAlign: "center", margin: 4 }}>your tickets will appear here</HeadingText>

          <View style={{ height: 100, width: 100 }}>
            <BagIcon color={"#FFF"} />
          </View>
          <HeadingText style={{ textAlign: "center", margin: 4 }}>signin</HeadingText>
          <View style={{}}>
            <Button
              onPress={() => {
                navigation.navigate("Login");
              }}
            >
              <View style={{ width: 70, transform: "rotate(180deg)" }}>
                <Arrow />
              </View>
            </Button>
          </View>
        </View>
      )}
      {/* <Button onPress={fetchTickets}>{fetching ? <ButtonLoadingSpinner /> : <WhiteText>refetch</WhiteText>}</Button> */}
    </ScrollView>
  );
}

const ticketStyles = StyleSheet.create({
  ticketsContainer: {
    // flexDirection: "row",
    // flexWrap: "wrap",
    // justifyContent: "center",
    width: "100%",
    flex: 1,
    marginTop: 10,
    // gap: 20,
  },
  ticketWrapper: {
    // flexBasis: "30%",
    // width: "100%",
    marginBottom: 10,
    // margin: "auto",
    // flexGrow: 1,
    height: "auto",
    width: "30%",
    margin: "auto",
  },
});

// {tickets.map((ticket) => {
//   return (
//     <View style={ticketStyles.ticketWrapper} key={ticket.id}>
//       {/* <WhiteText style={{ fontSize: 2 }}>{ticket.id}</WhiteText> */}
//       <TicketImg />
//     </View>
//   );
// })}
