export default function TicketIcon({ color }) {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 67.06 67.04"
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <polygon
            points=".8 66.52 30.38 .52 66.22 .52 33.51 66.52 .8 66.52"
            fill="#fff"
            stroke="#231f20"
            strokeMiterlimit="10"
            strokeWidth="1.04"
          />
          <g>
            <ellipse
              cx="41.38"
              cy="14.69"
              rx="7.51"
              ry="6.62"
              fill={color}
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <ellipse
              cx="33.86"
              cy="30.13"
              rx="7.51"
              ry="6.62"
              fill={color}
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <ellipse
              cx="24.86"
              cy="49.17"
              rx="11.61"
              ry="10.22"
              fill={color}
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="1.55"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
