import { useEffect, useRef, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { intentUrl } from "../libs/constant";
import magic from "../libs/MagicConfiguration";
import TicketImg from "./svg/TicketImg";
import QRCode from "qrcode";
import WhiteText from "./WhiteText";
import Drinker from "./svg/Drinker";
import NotDrinker from "./svg/NotDrinker";

const numPressText = {
  0: "",
  1: "this",
  2: "will",
  3: "check",
  4: "you",
  5: "in",
  6: "@",
  7: "the",
  8: "party",
  9: "k?",
};

export default function WalletTicket({ item, fetchTickets, readyForCheckin }) {
  const [numPresses, setNumPresses] = useState(0);
  const ref = useRef();
  const onPress = async () => {
    if (!readyForCheckin) {
      console.log("not ready");
      return;
    }
    if (numPresses < 2) {
      setNumPresses(numPresses + 1);
    } else {
      const did = await magic.user.getIdToken();
      const res = await fetch(intentUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${did}`,
        },
        body: JSON.stringify({ type: "checkIn", ticket_id: item.id }),
      }).then((r) => r.json());
      if (res.statusCode === 200) {
        fetchTickets();
        setNumPresses(0);
      }
    }
  };
  // useEffect(() => {
  //   if (item.id) {
  //     QRCode.toCanvas(ref.current, item.id, function (error) {
  //       if (error) console.error(error);
  //       console.log("success!");
  //     });
  //   }
  // }, [item]);
  return (
    <Pressable onPress={onPress} style={ticketStyles.ticketWrapper} key={item.id}>
      {/* <canvas ref={ref}></canvas> */}
      {item.ticketType === "drinker" ? <Drinker /> : <NotDrinker />}
      <View style={ticketStyles.numPresses}>
        <WhiteText style={ticketStyles.numPressesText}>{numPressText[numPresses]}</WhiteText>
      </View>
      <TicketImg checkedIn={item.checkedIn} />
    </Pressable>
  );
}

const ticketStyles = StyleSheet.create({
  ticketWrapper: {
    // flexBasis: "30%",
    // width: "100%",
    marginBottom: 10,
    // margin: "auto",
    // flexGrow: 1,
    height: "auto",
    maxHeight: "70vh",
    width: "50%",
    margin: "auto",
    alignItems: "center",
    position: "relative",
  },
  numPresses: {
    position: "absolute",
    top: "50%",
    zIndex: 9999,
  },
  numPressesText: {
    fontSize: 70,
    fontWeight: "bold",
  },
});
