export default function ProfileIcon({ color }) {
  return (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.11 67.35">
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path
            d="m48.14,67.35v-14.18s13.47-8.82,13.47-21.56c0-12.74-4.41-31.11-31.11-31.11h1.11c-26.7,0-31.11,18.37-31.11,31.11s13.47,21.56,13.47,21.56v14.18"
            fill="#fff"
            stroke="#231f20"
            strokeMiterlimit="10"
          />
          <circle cx="20.19" cy="23.24" r="4.7" fill={"#fff"} stroke="#231f20" strokeMiterlimit="10" />
          <circle
            cx="41.17"
            cy="22.24"
            r="8.27"
            // fill={color}
            style={{ transition: "fill 1s", fill: color }}
            stroke="#fff"
            strokeMiterlimit="10"
          />
          <path d="m12.35,35.64s13.73,18.53,37.08,2.28" fill="#fff" stroke="#231f20" strokeMiterlimit="10" />
        </g>
      </g>
    </svg>
  );
}
