export default function Arrow() {
  return (
    <svg id="b" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.89 60.52">
      <g id="c" data-name="Layer 1">
        <g>
          <polyline points="80.95 3.26 103.78 29.82 103.78 29.82 0 29.82" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="10" />
          <polyline points="78.44 56.59 107.49 33.72 103.78 29.82 0 29.82" fill="none" stroke="#231f20" strokeMiterlimit="10" strokeWidth="10" />
        </g>
      </g>
    </svg>
  );
}
