import * as Linking from "expo-linking";

const linking = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Root: {
        screens: {
          Tickets: "/tickets/:step?",
          Login: "/login",
          Wallet: "/wallet",
        },
      },

      Modal: "modal",
      NotFound: "*",
    },
  },
};

export default linking;
